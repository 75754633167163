<template>
  <div class="main-wrap">
    <!-- 导航栏 -->
    <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'Index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>数据字典</el-breadcrumb-item>
    </el-breadcrumb> -->
    <!-- 查询表单 -->
    <el-form :inline="true" :model="queryForm">
      <el-form-item>
        <el-input v-model="queryForm.name" placeholder="名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryForm.code" placeholder="代码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-input v-model="queryForm.parentId" placeholder="父级id"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="$auth($authData.system.dictGet)"
          type="primary"
          icon="el-icon-search"
          @click="queryData"
          >查询</el-button
        >
      </el-form-item>
      <el-form-item>
        <el-button
          v-if="$auth($authData.system.dictAdd)"
          type="success"
          icon="el-icon-plus"
          @click="initAddForm"
          >添加</el-button
        >
      </el-form-item>
    </el-form>
    <!-- 数据表 -->
    <el-table
      ref="dictTable"
      v-loading="loading"
      border
      stripe
      :data="tableData"
      element-loading-text="加载中"
      element-loading-spinner="el-icon-loading"
      height="1000"
    >
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column
        prop="name"
        label="名称"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="code"
        label="代码"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="value"
        label="值"
        min-width="120"
      ></el-table-column>
      <el-table-column
        prop="parentId"
        label="父级id"
        width="80"
      ></el-table-column>
      <el-table-column
        prop="bak"
        label="描述"
        min-width="120"
      ></el-table-column>
      <el-table-column
        fixed="right"
        label="操作"
        width="210"
        header-align="center"
        align="center"
      >
        <template slot-scope="scope">
          <el-button
            v-if="$auth($authData.system.dictAdd)"
            size="mini"
            type="text"
            icon="el-icon-plus"
            @click="addChildren(scope.row)"
            >添加子节点</el-button
          >
          <el-button
            v-if="$auth($authData.system.dictEdit)"
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="initEditForm(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      :current-page.sync="queryForm.pageNo"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size.sync="queryForm.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="queryData"
      @current-change="queryData"
      @prev-click="queryData"
      @next-click="queryData"
    >
    </el-pagination>
    <!-- 业务弹框-添加字典 -->
    <el-dialog
      title="添加字典"
      :visible.sync="dialogAdd"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="dictAddForm"
        :model="dictAddForm"
        :rules="dictRules"
        status-icon
        label-position="top"
        size="medium"
      >
        <el-form-item label="父级id">
          <el-input
            v-model="dictAddForm.parentId"
            placeholder="请输入父级id"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="dictAddForm.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="代码" prop="code">
          <el-input
            v-model="dictAddForm.code"
            placeholder="请输入代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="值" prop="value">
          <el-input
            v-model="dictAddForm.value"
            placeholder="请输入值"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="dictAddForm.bak"
            placeholder="请输入描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="addLoading" @click="addData"
          >确定</el-button
        >
        <el-button @click="dialogAdd = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 业务弹框-编辑字典 -->
    <el-dialog
      title="编辑字典"
      :visible.sync="dialogEdit"
      :close-on-click-modal="false"
      top="3%"
      width="35%"
    >
      <el-form
        ref="dictEditForm"
        :model="dictEditForm"
        :rules="dictRules"
        status-icon
        label-position="top"
        size="medium"
      >
        <el-form-item label="父级id">
          <el-input
            v-model="dictEditForm.parentId"
            placeholder="请输入父级id"
          ></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="dictEditForm.name"
            placeholder="请输入名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="代码" prop="code">
          <el-input
            v-model="dictEditForm.code"
            placeholder="请输入代码"
          ></el-input>
        </el-form-item>
        <el-form-item label="值" prop="value">
          <el-input
            v-model="dictEditForm.value"
            placeholder="请输入值"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input
            v-model="dictEditForm.bak"
            placeholder="请输入描述"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" :loading="editLoading" @click="editData"
          >确定</el-button
        >
        <el-button @click="dialogEdit = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Dict",
  data() {
    return {
      queryForm: {
        name: null,
        code: null,
        parentId: null,
        pageNo: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      loading: false,
      dictRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        code: [{ required: true, message: "请输入代码", trigger: "blur" }],
        value: [{ required: true, message: "请输入值", trigger: "blur" }],
      },
      dialogAdd: false,
      dictAddForm: {},
      addLoading: false,
      dialogEdit: false,
      dictEditForm: {},
      editLoading: false,
    };
  },
  mounted() {
    this.queryData();
  },
  methods: {
    queryData() {
      this.loading = true;
      this.$http
        .get("/system/dict/getPage", { params: this.queryForm })
        .then((response) => {
          if (response.data.code === 200) {
            this.tableData = response.data.data;
            this.total = response.data.count;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initAddForm() {
      this.dictAddForm = {};
      this.addLoading = false;
      this.dialogAdd = true;
    },
    addData() {
      this.$refs["dictAddForm"].validate((valid) => {
        if (valid) {
          this.addLoading = true;
          this.$http
            .post("/system/dict/add", this.dictAddForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogAdd = false;
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.queryData();
                  },
                });
              }
            })
            .finally(() => {
              this.addLoading = false;
            });
        }
      });
    },
    initEditForm(row) {
      this.dictEditForm = {
        id: row.id,
        name: row.name,
        code: row.code,
        value: row.value,
        parentId: row.parentId,
        bak: row.bak,
      };
      this.editLoading = false;
      this.dialogEdit = true;
    },
    editData() {
      this.$refs["dictEditForm"].validate((valid) => {
        if (valid) {
          this.editLoading = true;
          this.$http
            .post("/system/dict/edit", this.dictEditForm)
            .then((response) => {
              if (response.data.code === 200) {
                this.dialogEdit = false;
                this.$message({
                  message: "编辑成功",
                  type: "success",
                  duration: 1000,
                  onClose: () => {
                    this.queryData();
                  },
                });
              }
            })
            .finally(() => {
              this.editLoading = false;
            });
        }
      });
    },
    addChildren(row) {
      this.dictAddForm = {
        name: null,
        code: null,
        value: null,
        parentId: row.id,
        bak: null,
      };
      this.addLoading = false;
      this.dialogAdd = true;
    },
  },
};
</script>

<style scoped></style>
